import localforage from 'localforage';

import { Locale } from '../constants/locale-constants';

import { getLanguageCodeFromLocale } from './locale-helper';

export const PREFERRED_LOCALE_KEY = 'CINEAMO_WEB_PREFERRED_LOCALE';

export const setPreferredLocale = async (locale: Locale): Promise<boolean> => {
    try {
        // TODO: NEXT_LOCALE cookie is deprecated and does not longer work with Next 13+
        // NEXT_LOCALE is used to store a language which will be "preselected" when visiting the website again.
        // TODO: Add 'domain=.cineamo.test'
        document.cookie = `NEXT_LOCALE=${getLanguageCodeFromLocale(locale)}; max-age=31536000; path=/`;
        await localforage.setItem<string>(PREFERRED_LOCALE_KEY, locale);
        return true;
    } catch (err) {
        // logger.error('Error while setting the preferred locale', err);
        return false;
    }
};

export const getPreferredLocale = async (): Promise<Locale | null> => {
    try {
        const preferredLocale = await localforage.getItem<Locale>(PREFERRED_LOCALE_KEY);
        // const preferredLocale = getLocaleFromLanguage(getCookie('NEXT_LOCALE'));
        if (!preferredLocale || !Object.keys(Locale).includes(preferredLocale)) return null;
        return preferredLocale;
    } catch (err) {
        // logger.error('Error while getting the preferred locale', err);
        return null;
    }
};

// function getCookie(name: string): string | null {
//     const nameLenPlus = name.length + 1;
//     return (
//         document.cookie
//             .split(';')
//             .map((c) => c.trim())
//             .filter((cookie) => {
//                 return cookie.substring(0, nameLenPlus) === `${name}=`;
//             })
//             .map((cookie) => {
//                 return decodeURIComponent(cookie.substring(nameLenPlus));
//             })[0] || null
//     );
// }
