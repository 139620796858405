import classnames from 'classnames';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { useState } from 'react';

import { getMoviePosterBlurDataImage, getMoviePosterImage } from '../../../../helper/movie-helper';
import { MovieCopyrightDto } from '../../../../models/movie-copyright/MovieCopyrightDto.types';

import { CellContainerProps } from './CellContainer';

export function TopTenMoviePictureCell(props: CellContainerProps<MovieCopyrightDto>) {
    const { row, column } = props;

    const [error, setError] = useState(false);

    const { locale } = useRouter();

    const movie = row._embedded?.content?._embedded?.cineamoMovie;

    const css = { width: 'auto' };

    const moviePoster = getMoviePosterImage(movie, locale);
    const moviePosterBlurData = getMoviePosterBlurDataImage(movie, locale);

    return (
        <td className={classnames(column.columnWidth, 'py-8 px-12')}>
            <div className="flex flex-row space-x-16 h-120 overflow-hidden">
                <div className="relative w-auto h-full flex-none">
                    <Image
                        className="rounded-4 w-auto h-full"
                        style={css}
                        src={!error && moviePoster}
                        blurDataURL={!error && moviePosterBlurData}
                        onError={() => setError(true)}
                        alt={movie?.title || 'Movie Poster'}
                        width="200"
                        height="250"
                        unoptimized
                    />
                </div>
            </div>
        </td>
    );
}
